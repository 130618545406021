/* Colors */
$white: #FFFFFF;
$yellow: #FFF06A;
$orange: #FFA800;
$black: #050403;

/* Font Sizes */
$biggest: 70px;
$big: 27px;
$normal: 18px;

/* Contents */
$degreeSymbol: '\00b0';

/* Screen Sizes */
$mobileS: 480px;
$mobileMd: 590px;
$mobile: 768px;
$desktopS: 992px;
$desktopMiddle: 1200px;
$desktop: 1440px;