@import '_assets/scss/abstractions/variables.scss';

.social {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;

    @media (min-width: $mobile) {
        flex-direction: row;
    }

    .li.social-group {
        max-width: 160px;
        flex-wrap: wrap;
        display: grid;
        grid-template-columns: 70px 70px;
        align-content: center;
        align-items: center;

        @media (min-width: $mobileS) {
            max-width: 100%;
            flex-wrap: nowrap;
        }

        @media (min-width: $mobile) {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__item {
        width: 50px;
        height: 50px;
        background-color: $black;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px;
        cursor: pointer;

        &:hover {
            .icon {
                transform: scale(1.2);
            }
        }

        &:focus,
        &:active {
            .icon {
                opacity: .8;
            }
        }

        .a {
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
                width: 100%;
                transition: transform ease 0.2s, opacity ease 0.2s;
            }
        }

        @media (min-width: $desktopS) {
            width: 52px;
            height: 52px;
        }
    }
}