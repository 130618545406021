@import '_assets/scss/abstractions/variables.scss';

.scroll-button {
    position: absolute;
    top: 40%;
    left: 50%;
    width: 24px;
    height: 24px;
    margin-left: -12px;
    border-right: 2px solid #fff;
    border-top: 2px solid #fff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-animation: sdb05 1.5s infinite;
    animation: sdb05 1.5s infinite;
    box-sizing: border-box;
}

@-webkit-keyframes sdb05 {
    0% {
        -webkit-transform: rotate(-45deg) translate(0, 0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        -webkit-transform: rotate(-45deg) translate(10px, -10px);
        opacity: 0;
    }
}

@keyframes sdb05 {
    0% {
        transform: rotate(-45deg) translate(0, 0);
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        transform: rotate(-45deg) translate(10px, -10px);
        opacity: 0;
    }
}