@import '_assets/scss/abstractions/variables.scss';

.preview-phones {
    position: relative;
    display: flex;
    transform: scale(0.4);

    @media (min-width: $mobile) {
        transform: scale(0.5);
    }

    @media (min-width: $desktopS) {
        transform: scale(0.7);
    }

    @media (min-width: $desktopMiddle) {
        transform: scale(0.7) translateX(-25%);
    }

    @media (min-width: $desktop) {
        transform: scale(0.8) translateX(-20%);
    }

    .phone {
        position: relative;
        filter: drop-shadow(0px 10px 20px rgba(68, 41, 0, 0.68));

        // left phone
        &:nth-child(1) {
            position: absolute;
            transform: perspective(1000px) rotateY(30deg) translateX(-60%) scale(0.8) skewX(5deg);
            display: block;

            @media (min-width: $desktopS) {
                display: none;
            }

            @media (min-width: $desktopMiddle) {
                display: block;
            }
        }

        &:nth-child(2) {
            position: relative;
            z-index: 2;
            transform: translateZ(100px);
        }

        &:nth-child(3) {
            position: absolute;
            transform: perspective(1000px) rotateY(-30deg) translateX(60%) scale(0.8) skewX(-5deg);
            display: block;

            @media (min-width: $desktopS) {
                display: none;
            }

            @media (min-width: $desktopMiddle) {
                display: block;
            }
        }

        .img {
            max-width: 320px;
            width: 100%;

            &:nth-child(1) {
                position: relative;
                z-index: 2;
            }

            &:nth-child(2) {
                position: absolute;
                left: 0;
                top: 20px;
                z-index: 1;
                clip-path: inset(0 15px 5px round 30px);
            }
        }

    }
}