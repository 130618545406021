@import '_assets/scss/abstractions/variables.scss';
@import '_assets/scss/abstractions/mixins.scss';

.policy {
    margin-top: 65px;

    @media (min-width: $mobileS) {
        margin-top: 80px;
    }

    &_wrapper {
        border: 2px solid $black;
        margin: 2rem;
        padding: 2rem;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow-y: scroll;
        height: 60vh;

        @media (min-width: $mobileS) {
            height: 60vh;
        }

        @media (min-width: $desktop) {
            height: calc(100vh - 290px);
        }
    }

    &_wrapper a {
        color: $black;
    }

    &_wrapper a {
        @include linkUnderline;
    }


    .list {
        font-size: 20px;
        line-height: 1.55
    }

    .list ul {
        padding: 20px
    }

    .list ul li {
        list-style: disc;
        line-height: 1.55
    }

    .list ul li li {
        padding: 0px;
        list-style: circle
    }


    p {
        margin: 0;
        font-size: $normal;
        line-height: 1.4
    }

    p:not(:last-child) {
        margin-bottom: 1.5rem
    }

    h2 {
        font-size: $big;
    }
}