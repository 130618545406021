@import '_assets/scss/abstractions/variables.scss';

.header {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;

    @media (min-width: $desktopMiddle) {
        justify-content: space-between;
    }

    &.container-mobile {
        justify-content: flex-start;
        margin: 2rem 2rem 0;
        width: 100%;
    }

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &__img {
            width: 230px;

            @media (min-width: $mobileS) {
                width: 330px;
            }
        }
    }

    .social {
        display: none;
        font-size: $big;

        @media (min-width: $desktopMiddle) {
            display: flex;
        }
    }
}