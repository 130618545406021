@import '_assets/scss/abstractions/variables.scss';
@import '_assets/scss/abstractions/mixins.scss';

.footer {
    .links {
        display: none;
        @media (min-width: $mobile) {
            display: block;
        }

        @media (min-width: $desktop) {
            display: none;
        }
    }

    .sub-info {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 30px;
        position: fixed;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;

        @media(min-width: $mobile) {
            width: 50%;
        }

        .copyright {
            display: flex;
            align-items: center;
            margin-right: 30px;
            width: max-content;

            .p {
                margin-left: 5px;
            }
        }

        .policy-navlink {
            @include linkUnderlineHover;
            width: max-content;
        }
    }
}