@mixin linkUnderline {

    text-decoration: none;
    background-image: linear-gradient(currentColor, currentColor);
    background-position: 0% 100%;
    background-repeat: no-repeat;
    background-size: 100% 2px;
    width: 30px;
    text-overflow: ellipsis;

}

@mixin linkUnderlineHover {

    @include linkUnderline;
    background-size: 0% 2px;
    transition: background-size 0.3s;
    transition: 0.3s;
    overflow: hidden;

    &:hover {
        background-size: 100% 2px
    }
}