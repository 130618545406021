@import '_assets/scss/abstractions/variables.scss';

.more-links {
    display: block;
    text-transform: uppercase;
    font-size: $normal;
    text-align: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scale(1.8);
    width: 50%;
    top: 35%;
    opacity: 1;
    visibility: visible;
    transition: top 1s ease, opacity 1s cubic-bezier(0.95, 0.12, 0, 0.85), visibility 0.4s cubic-bezier(0.4, 0, 0, 1);


    @media (min-width: $mobile) {
        width: 100%;
        transform: translateX(-50%);
        top: auto;
        bottom: 0;
        // margin-bottom: 80px;
        margin-bottom: 8vh;
        opacity: 1;
    }

    @media (min-width: $desktopS) {
        font-size: calc($big / 1.5);
    }

    @media (min-width: $desktopMiddle) {
        display: none;
    }

    @media (min-width: $desktop) {
        font-size: $big;
    }

    &.active {
        visibility: hidden;
        top: 100%;
        opacity: 0;
        transition: top 1s ease, opacity 0.4s cubic-bezier(0.4, 0, 0, 1), visibility 0.4s cubic-bezier(0.4, 0, 0, 1);

        @media (min-width: $mobile) {
            top: auto;
            opacity: 1;
            visibility: visible;
        }
    }

    .p {
        display: none;

        @media (min-width: $mobile) {
            display: inline;
        }
    }
}