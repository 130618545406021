@import '_assets/scss/abstractions/variables.scss';

.carousel.carousel-slider {
    .carousel-status {
        display: none;
    }

    .control-arrow.control-next,
    .control-arrow.control-prev {
        margin: auto;
        padding: 10px;
        top: 20%;
        opacity: 1;

        @media (min-width: $mobile) {
            padding: 5px;
            top: 0;
        }

        &:hover {
            background: transparent;
        }

        &:active,
        &:focus {
            opacity: .4;
        }
    }

    .control-arrow.control-next {
        right: 5px;

        @media (min-width: $mobile) {
            right: 30px;
        }
    }

    .control-arrow.control-prev {
        left: 5px;

        @media (min-width: $mobile) {
            left: 30px;
        }
    }

    .control-dots {
        bottom: 0;

        @media (min-width: $desktopS) {
            bottom: 13%;
        }

        @media(min-width: $desktop) {
            bottom: 5%
        }

        .dot {
            width: 25px;
            height: 8px;
            background-color: #FFFFFF;
            border-radius: 4px;
            box-shadow: none;
        }
    }
}